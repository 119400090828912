import React from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';
import { useTheme } from '@components/theme';
import { getResizedImageUrl } from '@lib/image';
import browser from '@utils/browser/browser';
import { isOpenInstallAppBannerDismissed } from '@lib/cookie/openInstallAppBannerDismissed';
import styles from './Hero.module.scss';

interface IHero {
  eyebrow: string;
  title: string;
  ctaText: string;
  ctaLink: string;
  ctaOnClick: (e: any) => void;
  className?: string;
}

export default function Hero({
  eyebrow,
  title,
  ctaText,
  ctaLink,
  ctaOnClick,
  className = '',
}: IHero) {
  const theme = useTheme();

  const shouldPadHeroText = browser.isIOS() && !isOpenInstallAppBannerDismissed();

  return (
    <section className={classNames(styles.Hero, className)}>

      <div className={styles.AspectRatioBox}>
        <div className={styles.AspectRatioBoxInside}>

          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={getResizedImageUrl({
                quality: 90,
                url: 'https://static.havenly.com/home/hero_2025_desktop.png ',
                width: 1620
              })
              }
            />
            <img
              src={getResizedImageUrl({
                quality: 90,
                url: 'https://static.havenly.com/home/hero/2025/hero_2025_mobile.jpg',
                width: 750
              })}
              alt="Hero"
              className={styles.HeroImage}
            />
          </picture>

          <div className={styles.Backdrop} />
        </div>

      </div>

      <div className={`${styles.ContentContainer} ${shouldPadHeroText ? styles.Padded : ''}`}>
        <div
          className={classNames(
            theme.styles.Overline,
            styles.Eyebrow
          )}
        >
          {eyebrow}
        </div>

        <h1 className={classNames(styles.Title)}>
          {title}
        </h1>

        <NextLink
          href={ctaLink}
          prefetch
        >
          <button
            type="button"
            className={classNames(theme.styles.Button,
              theme.styles.Primary,
              styles.CTAButton)}
            data-test={'homepage-hero-cta-link'}
            onClick={ctaOnClick}
          >
            {ctaText}
          </button>
        </NextLink>
      </div>
    </section>
  );
}
