/* eslint-disable camelcase */
/* eslint-disable max-len */
import dynamic from 'next/dynamic';

import BeforeAndAfter from '@components/pages/Home/components/BeforeAndAfter/BeforeAndAfter';
import DesignHelp from '@components/pages/Home/components/DesignHelp/DesignHelp';
import HappinessGuarantee from '@components/pages/Home/components/HappinessGuarantee/HappinessGuarantee';
import HowHavenlyWorks from '@components/pages/Home/components/HowHavenlyWorks/HowHavenlyWorks';
import defaultLinkTracking from '@components/pages/Home/lib/defaultLinkTracking';
import MobileAppConversionBanner from '@components/shared/MobileAppConversionBanner/MobileAppConversionBanner';
import IPrismicReviews from '@components/shared/Reviews/i-prismic-reviews';
import { IReview } from '@components/shared/Reviews/Reviews';
import env from '@lib/envs/env';
import { IPrismicImage, IPrismicText, IPrismicUrl } from '@lib/prismic/types';
import { asText } from '@lib/prismic/util/document';
import Hero from './components/Hero/Hero';

const { IOS_APP_URL_SCHEME_BASE } = env;

const Press = dynamic(() => import('./components/Press/Press'));
const DesignPackages = dynamic(() => import('./components/DesignPackages/DesignPackages'));
const OurPartners = dynamic(() => import('./components/OurPartners/OurPartners'));
const Reviews = dynamic(() => import('@components/shared/Reviews/Reviews'));

interface IHomeProps {
  prismicData: IPrismicData;
  featuredBoards: any;
  unbounceBannerHeight: number;
}

interface IPrismicData extends IPrismicReviews {
  hero_subtitle?: IPrismicText[];
  hero_cta_text: IPrismicText[];
  hero_cta_link: IPrismicUrl;
  hero_cta_logged_in_link: IPrismicUrl;
  our_partners_eyebrow: IPrismicText[];
  our_partner_image: IPrismicImage;
  our_partners_text: IPrismicText[];
}

const Home = ({ prismicData, unbounceBannerHeight }: IHomeProps) => {
  const {
    //hero_subtitle,
    //hero_cta_text,
    //hero_cta_link,
    //hero_cta_logged_in_link,
    our_partners_eyebrow,
    our_partner_image,
    our_partners_text,
    reviews_subtitle,
    reviews_cta,
    reviews_list,
  } = prismicData;

  return (
    <>
      <Hero
        eyebrow="THE #1 INTERIOR DESIGN SERVICE"
        title="Designing your dream home just became a reality."
        ctaText="GET STARTED"
        ctaLink="/interior-design-style-quiz/style-inspiration"
        ctaOnClick={(e) => {
          defaultLinkTracking('get_started', e?.target);
        }}
      />

      <HowHavenlyWorks />

      <DesignPackages />

      <Press />

      <BeforeAndAfter />

      <Reviews
        title="Reviews"
        newHomepage
        subtitle={asText(reviews_subtitle)}
        cta={asText(reviews_cta)}
        reviews={reviews_list.map((review: any): IReview => ({
          author: asText(review.author),
          title: asText(review.title),
          text: asText(review.text),
          stars: review.stars,
        }))}
      />

      <DesignHelp />

      <OurPartners
        eyebrow={asText(our_partners_eyebrow)}
        image={our_partner_image}
        title={asText(our_partners_text)}
        newHomepage
      />

      <MobileAppConversionBanner
        iosDeeplinkPath={`${IOS_APP_URL_SCHEME_BASE}://havenly.com/`}
        unbounceBannerHeight={unbounceBannerHeight}
      />

      <HappinessGuarantee />
    </>
  );
};

export default Home;
